import React from "react";
import "./about.sass";
import { FaAngleRight } from "react-icons/fa";

export default function About() {
  return (
    <section className="about" id="about">   
      <div className="informations">
        <div className="contentText">
          <h1>Benefício BPC/LOAS</h1>
          <h2>
          Você pode ter direito a receber um benefício de R$ 1.412,00 
          mensais MESMO SEM PAGAR INSS!
          </h2>
          <strong>Quem tem direito?</strong>
          
          <div className="containerTopics">
            <div className="topic">
              <span className="span">1</span>
              <p>Adultos baixa renda com <span className="span-topic">DEFICIÊNCIA</span> que não conseguem trabalhar.</p>
            </div>

            <div className="topic">
              <span className="span">2</span>
              <p>Crianças baixa renda com <span className="span-topic">DEFICIÊNCIA OU AUTISMO</span>.</p>
            </div>

            <div className="topic">
              <span className="span">3</span>
              <p>Idosos baixa renda com <span className="span-topic">MAIS DE 65 ANOS</span> sem aposentadoria.</p>
            </div>
          </div>

          <a href="https://wa.me/5511978536719" target="_blank" className="contract">
            Quero receber o benefício
          </a>
        </div>
      </div>

      <div className="contentAbout" id="contentAbout">
        <div className="contentDiv">
          <p className="logoText">RC Advocacia</p>

          <h1>
            SOLUÇÕES SIMPLES E <span>INTELIGENTES</span> PARA QUESTÕES COMPLEXAS
          </h1>

          <p className="textOne">
            Temos prazer em assumir casos complexos e difíceis, e mergulhar na
            busca da solução, pois confiamos muito no preparo da nossa equipe.
          </p>

          <p className="textTwo">
            Nosso escritório é referência em inovação e tecnologia, mas nós
            acreditamos que precisamos ir muito além. Construir uma advocacia
            voltada para as pessoas e empresas que trabalham e produzem, e
            merecem respeito aos seus direitos.
          </p>
        </div>

        <a href="https://wa.me/5511978536719" target="_blank">
          ENTRE EM CONTATO
          <FaAngleRight className="icoN" />
        </a>
      </div>

      
    </section>
  );
}
