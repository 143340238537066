import React, { useState, useRef } from "react";
import Burger from "@animated-burgers/burger-squeeze";
import "@animated-burgers/burger-squeeze/dist/styles.css";
import logo from "../../img/logo-empresa.png";
import "./header.sass";

export default function Header() {
  const [open, setOpen] = useState(false);
  const hamb = useRef(null);

  const openHamburguer = () => {
    setOpen(!open);

    if (open === false) {
     // hamb.current.style.opacity = "1";
       hamb.current.style.display ='flex'
    } else {
       hamb.current.style.display ='none'
     // hamb.current.style.opacity = "0";
    //  hamb.current.style.zIndex = -99;
    }
  };

  return (
    <header>
      <div className="logo-Name">
        <img src={logo} alt="logo" className="logo" />
        <p>Rodrigo Cardoso</p>
      </div>

      <nav>
        <Burger isOpen={open} onClick={openHamburguer} id="burguer" />

        <ul ref={hamb} className="menu">
          <a href="#home">
            <li>Início</li>
          </a>
          <a href="#contentAbout">
            <li>Escritório</li>
          </a>
          <a href="#aboutRodrigo">
            <li>Sobre</li>
          </a>
          <a href="#contact">
            <li>Contato</li>
          </a>
          
        </ul>
      </nav>
    </header>
  );
}
