import React from 'react';
import './style.sass'
import { FaAngleRight, FaCalculator, FaFileInvoiceDollar, 
  FaUserClock, FaUserPlus, FaUserInjured, FaWheelchair, FaBlind, FaCross, FaWhatsapp, FaUserTie   } from "react-icons/fa"

export default function MainHeader() {
 return (
   <main className='containerHeader' id='home'>
     <div className='contactheader'>
       <h2>Advocacia Trabalhista e Previdenciária</h2>
       <h1>Agende uma consulta hoje mesmo e comece a proteger 
        seus direitos Trabalhistas e Previdenciários.</h1>
        <p>Nossa equipe está pronta para ajudá-lo a navegar pelos desafios do sistema previdenciário e 
          trabalhista brasileiro. 
          Entre em contato conosco agora e comece a construir um futuro financeiro mais seguro e tranquilo.</p>

       <a href="https://wa.me/5511978536719" target="_blank">
          RESOLVA SEU PROBLEMA
          <FaWhatsapp className='icon'/>
       </a>
     </div>

     <div className="services">
        <div className="services-content">
           <h1>Confira o que <span>FAZEMOS DE MELHOR</span> por você</h1>
           <p className='p'>Atuamos de forma virtual  e presencial com segurança, ética e agilidade.</p>
        </div>

        <div className="list-services">
           <div>
              <p className='iconP'><FaCalculator className='iconAdv'/>Planejamento Previdenciário</p>
              <p className='iconP'><FaFileInvoiceDollar className='iconAdv'/> Aposentadoria Especial</p>
              <p className='iconP'><FaUserClock className='iconAdv'/>Aposentadoria Por Tempo de Contribuição</p>
              <p className='iconP'><FaUserInjured  className='iconAdv'/>Auxílio Acidente</p>
              <p className='iconP'><FaWheelchair className='iconAdv'/> Loas - BPC - Idoso e Deficiente</p> 
           </div>
          <div>
              <p className='iconP'><FaBlind  className='iconAdv'/> Benefícios por Incapacidade</p>
              <p className='iconP'><FaCross  className='iconAdv'/> Pensão por morte</p>
              <p className='iconP'><FaUserTie  className='iconAdv'/> Reconhecimento de Vínculo de Emprego</p>
              <p className='iconP'><FaUserClock className='iconAdv'/> Recisão Indireta</p>
              <p className='iconP'><FaUserInjured className='iconAdv'/> Acidente do Trabalho/Doença Ocupacional</p>
          </div>
        </div>
     </div>
     
   </main>
 );
}