import React from "react";
import "./footer.sass";
import logo from '../../img/logo-empresa.png'
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup
  } from 'react-leaflet'
import "leaflet/dist/leaflet.css"
import { Icon } from "leaflet";
import {
    FaWhatsapp,
    FaFacebookF,
    FaEnvelope,
    FaInstagram,
    FaMapMarkerAlt 
  } from "react-icons/fa"


export default function Footer() {

const position = [51.505, -0.09]

  return (
    <footer id="contact">
      <div className="info-logo">
          <div className="rodrigo-escritorio">
            <img src={logo} alt="" />
            <div className="texts">
            <h1>Advocacia Trabalhista e Previdenciária</h1>
            <p>Dr. Rodrigo Cardoso</p>
            </div>
          </div>

          <div className="redesMKT">
            <a href="https://www.facebook.com/profile.php?id=61553910971047" target="_blank">
              <FaFacebookF/>     
            </a>

            <a href="https://instagram.com/_rcadvocacia?igshid=OGQ5ZDc2ODk2ZA==" target="_blank">
              <FaInstagram/>         
            </a>
          </div>

      </div>     

        <div className="contactFooter">

            <h1>Contatos</h1> 

          <a href="https://wa.me/5511978536719" target="_blank">
              <FaWhatsapp className="iconSocial" /> 11978536719
          </a>

          <a href="mailto:rodrigocardoso@rodrigocardosoadvocacia.com" target="_blank">
              <FaEnvelope className="iconSocial" /> rodrigocardoso@<br/>rodrigocardosoadvocacia.com
          </a>

          <p>
            <FaMapMarkerAlt  className="iconSocial" /> 
            Rua Tuiuti, 2011 - Tatuapé, São Paulo - SP, 03307-005
          </p>
        </div>

        <div className="location">
           <h1>Localização</h1>
           
     <MapContainer center={[-23.54318166053528, -46.57473774645152]} zoom={13} scrollWheelZoom={true}>
             <TileLayer
                 url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
             />
           <Marker position={[-23.54318166053528, -46.57473774645152]}
           icon={
            new Icon({
                iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
                iconUrl: require("leaflet/dist/images/marker-icon.png"),
                shadowUrl: require("leaflet/dist/images/marker-shadow.png")
            })
           }
           >
               <Popup>
                  Rua Tuiuti, 2011 - Tatuapé, <br/> São Paulo - SP, 03307-005
               </Popup>
           </Marker>
     </MapContainer>

           <div className="container">
              <h1>Advocacia Trabalhista e Previdenciária</h1>
              <p>Dr. Rodrigo Cardoso</p>
              <a href="https://maps.app.goo.gl/T5WwwH2oe5obwDdp9" target="_blank">Rua Tuiuti, 2011 - Tatuapé, São Paulo - SP, 03307-005</a>
           </div>
        </div>

      
    </footer>
  );
}
