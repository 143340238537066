import React from "react";
import "./rodrigo.sass";
import advogado from '../../img/advogado2.jpeg'

export default function Rodrigo() {
  return (
    <section className="aboutRicardo" id="aboutRodrigo">
      <div className="contentAboutRicardo">
        <h1>Dr. Rodrigo Cardoso</h1>

        <h2>OAB / SP - 377.487... </h2>

        <h3>Advogado e Sócio Proprietário do Escritório Rodrigo Cardoso Advocacia Previdenciária e Trabalhista. </h3>

        <p>
        Dr. Rodrigo Cardoso é formado pela Faculdade UNIP, especialista em 
        Direito Previdenciário e Trabalhista, possui diversos cursos de especialização na área. Mais de 9 anos 
        de atuação junto ao INSS. Seu benefício foi negado? Podemos lhe auxiliar.
        </p>

        <a href="https://wa.me/5511978536719" target="_blank">Entre em contato</a>

      </div>

      <img src={advogado} alt="" />

      
    </section>
  );
}
