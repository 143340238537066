import Home from "./pages/Home"
import './global.sass'

function App() {
  return (
    <div className="App">
       <Home/>
    </div>
  );
}

export default App;
