import React from 'react';
import Header from '../../components/Header'
import MainHeader from '../../components/MainHeader'
import About from '../../components/About';
import Rodrigo from '../../components/Rodrigo';
import Footer from '../../components/Footer'

export default function Home() {
 return (
   <main>
     <Header/>
     <MainHeader/>
     <About/>
     <Rodrigo/>
     <Footer/>
   </main>
 );
}